element {
  --main-font-color: rgb(238, 230, 222)
}

@media (min-width:299px) {
  /* Mobile */
  .Body-text {
    font-size: 16px;
  }
  .Body-header {
    font-size: 46px;
  }
  .footer {
    font-size:10px;
  }
}

@media (min-width:750px) {
  /* iPad */
  .Body-text {
    font-size: 24px;
  }
  .Body-header {
    font-size: 80px;
  }
  .footer {
    font-size:12px;
  }
}

@media (min-width:1280px) {
  /* Desktop */
  .Body-text {
    font-size: 20px;
  }
  .Body-header {
    font-size: 90px;
  }
  .footer {
    font-size:10px;
  }
}

.App {
  text-align: left;
  background-color: #101215;
}

.App-header {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
  padding: 1%;
  padding-left: 2%;
  vertical-align: middle;
  line-height: 1.6;
}

.Body-header {
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
}

.Body-text {
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
}

.blinking-cursor {
  font-weight: 100;
  font-size: 30px;
  color: #2E3D48;
  -webkit-animation: 1.5s blink step-end infinite;
  -moz-animation: 1.5s blink step-end infinite;
  -ms-animation: 1.5s blink step-end infinite;
  -o-animation: 1.5s blink step-end infinite;
  animation: 1.5s blink step-end infinite;
}

@keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: var(--main-font-color);
  }
}

@-moz-keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: var(--main-font-color);
  }
}

@-webkit-keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: var(--main-font-color);
  }
}

@-ms-keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: var(--main-font-color);
  }
}

@-o-keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: var(--main-font-color);
  }
}

a:link {
  color: green;
  background-color: transparent;
  text-underline-offset: 0.1em;
}

a:visited {
  color: red;
  background-color: transparent;
  text-underline-offset: 0.1em;
}

a:hover {
  color: yellow;
  background-color: transparent;
  text-underline-offset: 0.1em;
}

a:active {
  color: blue;
  background-color: transparent;
  text-underline-offset: 0.1em;
}


.footer {
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  position: fixed;
  background-color: black;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1%;
  text-align: center;
  color: gray;
  margin-top: 10%;
}

.footer-logos {
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 14px;
  height: auto;
}
